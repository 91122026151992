@charset "UTF-8";
@font-face {
  font-family: "Montserrat-c";
  src: url("../fonts/Montserrat/Montserrat-Regular.ttf");
}
@font-face {
  font-family: "Montserrat-semibold";
  src: url("../fonts/Montserrat/Montserrat-SemiBold.ttf");
}
@font-face {
  font-family: "Montserrat-bold";
  src: url("../fonts/Montserrat/Montserrat-Bold.ttf");
}
@font-face {
  font-family: "Montserrat-light";
  src: url("../fonts/Montserrat/Montserrat-Light.ttf");
}
html body {
  font-family: "Montserrat-semibold", sans-serif;
  margin: 0;
}
html body a {
  text-decoration: none;
  color: #FFFFFF;
}
html body a:hover {
  color: #FFFFFF;
}
html body .container-fluid {
  padding: 0 50px;
}
html body #ylibka {
  color: #8855A6;
}
html body nav {
  font-family: "Montserrat-c", sans-serif;
  font-size: 20px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8px);
}
html body nav .navbar-brand {
  width: 50%;
}
html body nav .navbar-collapse {
  justify-content: space-between;
}
html body nav .navbar-collapse .contact-phone {
  color: #8855A6;
  font-family: "Montserrat-semibold", sans-serif;
}
html body nav .navbar-collapse .navbar-nav li.nav-item {
  margin-right: 20px;
}
html body nav .navbar-collapse .navbar-nav li.nav-item:last-child {
  margin-right: 0;
}
html body .main-section {
  display: flex;
  background: url("../images/main-bg.jpg") no-repeat;
  background-size: cover;
  width: 100%;
  height: 796px;
  align-items: center;
  background-position-x: -383px;
}
html body .main-text {
  color: #343434;
  font-size: 47px;
  margin-bottom: -19px;
}
html body #about {
  background-color: white;
  display: flex;
  align-items: center;
}
html body #about #img-about-women {
  height: 350px;
  width: 350px;
  border-radius: 20px;
}
html body #about .about-block-text {
  display: flex;
  align-items: center;
  justify-content: center;
}
html body #about .about-block-text .box {
  text-align: center;
}
html body #about .about-block-text .box hr {
  height: 2px;
  width: 100px;
  margin: 30px 40% 30px 45%;
}
html body #about .about-block-text .box p {
  font-family: "Montserrat-light", sans-serif;
  font-size: 20px;
  padding-left: 50px;
  padding-right: 50px;
}
html body #services {
  background-color: #F3EFF8;
}
html body #services #price-button {
  width: 25%;
  border: none;
  border-radius: 30px;
  background-color: #C69FCD;
  font-size: 24px;
  font-family: "Montserrat-bold", sans-serif;
  color: #F2F2F2;
  padding: 15px;
  margin-top: 30px;
}
html body #services .gallery-block {
  align-items: center;
}
html body #services .gallery-block .arrow {
  vertical-align: middle;
  cursor: pointer;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 48px;
}
html body #services .gallery-block .arrow.arrow-prev {
  transform: rotate(180deg);
}
html body #services .section-title {
  margin-top: 80px;
  margin-bottom: 40px;
}
html body #services .services {
  font-family: "Montserrat-c", sans-serif;
}
html body #services .services ul.services-categories hr {
  margin: 0;
}
html body #services .services ul.services-categories .services-category {
  font-family: "Montserrat-bold", sans-serif;
  color: #8855A6;
  list-style: none;
  font-size: 24px;
}
html body #services .services ul.services-categories .services-category .plus {
  color: #757575;
  font-size: 40px;
  font-family: Montserrat-light, serif, sans-serif;
  font-weight: normal;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
html body #services .services ul.services-categories .services-table {
  margin-bottom: 30px;
  padding: 0;
  display: none;
  margin-top: 30px;
}
html body #services .services ul.services-categories .services-table li {
  font-family: "Montserrat-light", sans-serif;
  list-style: none;
  color: #2D2D2D;
  font-weight: normal;
  font-size: 20px;
}
html body #services .services ul.services-categories .services-table li .service-title {
  margin-right: 20px;
}
html body #advantages {
  background-color: #F3EFF8;
}
html body #advantages .section-title {
  margin-top: 80px;
  margin-bottom: 40px;
}
html body #advantages .advantages-block {
  margin: 22.5px 0 22.5px 0;
  border-radius: 17px;
  padding: 38px 48px 38px 60px;
  background-color: #ffffff;
}
html body #advantages .advantages-block:nth-child(3) {
  margin-bottom: 0;
}
html body #advantages .advantages-block .advantages-title {
  font-size: 24px;
  line-height: 1.2;
  color: #8855A6;
}
html body #advantages .advantages-block .advantages-description {
  font-family: Montserrat-light, serif;
  color: #2D2D2D;
  margin-top: 24px;
  font-size: 20px;
  line-height: 1.2;
}
html body #documents {
  background-color: #F3EFF8;
}
html body #documents .section-title {
  margin-top: 80px;
  margin-bottom: 40px;
}
html body #documents .images-row-2 {
  margin-top: 22.5px;
}
html body #documents .col-document-image img {
  width: 100%;
}
html body #contacts {
  background-color: #F3EFF8;
}
html body #contacts .section-title {
  margin-top: 80px;
  margin-bottom: 40px;
}
html body #contacts .row {
  flex-wrap: nowrap;
}
@media (min-width: 768px) {
  html body #contacts .row {
    flex-wrap: wrap;
  }
}
html body #contacts .row .col-contact-description .contact-description-block {
  text-align: center;
  background-color: #FFFFFF;
  border-radius: 17px;
  padding: 42px 44px 39px 35px;
  color: #2D2D2D;
  font-size: 20px;
  font-family: "Montserrat-c", sans-serif;
  margin-bottom: 22.5px;
}
html body #contacts .row .col-contact-description .contact-description-block a {
  color: #2D2D2D;
}
html body #contacts .row .col-contact-description .contact-description-block a:hover {
  color: #2D2D2D;
}
html body #contacts .row .col-contact-map-с iframe {
  width: 100%;
  height: 459px;
  border-radius: 17px;
}
html #footer {
  background: rgba(162, 209, 252, 0.4);
  font-family: "Montserrat-c", sans-serif;
  font-size: 20px;
  color: #FFFFFF;
}
html #footer .footer {
  padding: 40px 0 50px 0;
  background: linear-gradient(180deg, rgba(162, 209, 252, 0) 0%, #CBA0DA 100%);
}
html #footer .footer ul {
  padding-left: 0;
  list-style: none;
}
html #footer .footer ul li {
  cursor: pointer;
}